import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import ReactDatePicker from "react-datepicker";
import { FileUploader } from "react-drag-drop-files";
import {
  Checkbox,
  Field,
  InfoLabel,
  Input,
  Textarea,
  Button,
  Spinner,
  TabList,
  Tab,
  Card,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTrigger,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { ReOrderDotsVerticalFilled } from "@fluentui/react-icons";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CalendarSVG, CloseCircleSVG } from "../assets/svgs";
import { useDialogStyles, useFieldStyles } from "../fluentStyles";
import {
  createOpening,
  getOpening,
  Interview,
  JobOpening,
  resetPostingStatus,
  updateOpening,
} from "../redux/features/openingSlice";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import localDateToDateString from "../utils/localDateToDateString";
import "react-datepicker/dist/react-datepicker.css";
import { getTeam, TeamMember } from "../redux/features/companyProfileSlice";

const tabsList = ["Job Details", "Interviews"] as const;
type Tab = (typeof tabsList)[number];

const CompanyOpportunityEdit = ({ mode }: { mode: "create" | "update" }) => {
  const [tab, setTab] = useState<Tab>(tabsList[0]);
  const [openingRequested, setOpeningRequested] = useState(mode === "create");
  const [teamRequested, setTeamRequested] = useState(false);

  const user = useAppSelector((state) => state.auth.user);
  const opening = useAppSelector((state) => state.opening.opening);
  const openingStatus = useAppSelector((state) => state.opening.status);
  const team = useAppSelector((state) => state.companyProfile.team);
  const teamStatus = useAppSelector((state) => state.companyProfile.status);

  const { openingId } = useParams();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const companyId = user?.companyIds[0];
  const loaded =
    (mode === "create" ||
      (openingRequested && openingStatus === "succeeded")) &&
    teamRequested &&
    teamStatus === "succeeded";

  useEffect(() => {
    setOpeningRequested(mode === "create");
    setTeamRequested(false);
  }, [mode, openingId]);

  useEffect(() => {
    if (mode === "update" && openingId && !openingRequested) {
      const controller = new AbortController();

      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(
          getOpening({
            id: openingId,
            getInterviews: true,
            token,
            signal: controller.signal,
          }),
        );
        setOpeningRequested(true);
      })();

      return () => {
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, openingId]);

  useEffect(() => {
    if (companyId && !teamRequested) {
      const controller = new AbortController();

      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(
          getTeam({
            id: companyId,
            token,
            signal: controller.signal,
          }),
        );
        setTeamRequested(true);
      })();

      return () => {
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    document.title =
      mode === "create" ? "Create an opportunity" : "Update an opportunity";
  }, [mode]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <Breadcrumb
          text={mode === "create" ? "New Opportunity" : "Update Opportunity"}
        />
        <div className="flex gap-5 self-stretch items-stretch">
          {loaded ? (
            <div className="card flex-col gap-6 w-full">
              <TabList
                size="large"
                appearance="subtle"
                selectedValue={tab}
                onTabSelect={(_evt, data) => setTab(data.value as Tab)}
              >
                {tabsList.map((tab) => (
                  <Tab key={`tab-${tab}`} id={tab} value={tab}>
                    {tab}
                  </Tab>
                ))}
              </TabList>
              <Form mode={mode} opening={opening} team={team} tab={tab} />
            </div>
          ) : (
            <div className="flex-1 flex flex-col items-center justify-center gap-8">
              <Spinner size="huge" />
              <div className="flex flex-col gap-1 items-stretch text-center">
                <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                  Fetching data
                </p>
                <p className="text-sm font-medium text-[#727E94]">
                  Just a few seconds left
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Breadcrumb = ({ text }: { text: string }) => {
  return <div className="text-lg font-semibold text-[#22234A]">{text}</div>;
};

interface JobObject
  extends Required<
    Omit<
      JobOpening,
      "id" | "companyRecordId" | "jobDescriptionLink" | "status" | "candidates"
    >
  > {
  jobDescriptionLink?: string;
  jobDescription?: File;
}

const Form = ({
  mode,
  opening,
  team,
  tab,
}: {
  mode: "create" | "update";
  opening?: JobOpening;
  team: TeamMember[];
  tab: Tab;
}) => {
  const [jobObject, setJobObject] = useState<JobObject>({
    role: "",
    jobDescriptionLink: "",
    jobDescription: undefined,
    scope: "",
    fullTimeConversionPossible: false,
    budget: "",
    desiredStartDate: "",
    matchCriteria1: "",
    matchCriteria2: "",
    matchCriteria3: "",
    interviews: [],
  });

  const postingStatus = useAppSelector((state) => state.opening.postingStatus);
  const openingId = useAppSelector((state) => state.opening.opening?.id);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (mode === "create") {
      setJobObject({
        role: "",
        jobDescriptionLink: "",
        jobDescription: undefined,
        scope: "",
        fullTimeConversionPossible: false,
        budget: "",
        desiredStartDate: "",
        matchCriteria1: "",
        matchCriteria2: "",
        matchCriteria3: "",
        interviews: [],
      });
    } else {
      setJobObject({
        role: opening?.role ?? "",
        jobDescriptionLink: opening?.jobDescriptionLink ?? "",
        jobDescription: undefined,
        scope: opening?.scope ?? "",
        fullTimeConversionPossible:
          opening?.fullTimeConversionPossible ?? false,
        budget: opening?.budget ?? "",
        desiredStartDate: opening?.desiredStartDate ?? "",
        matchCriteria1: opening?.matchCriteria1 ?? "",
        matchCriteria2: opening?.matchCriteria2 ?? "",
        matchCriteria3: opening?.matchCriteria3 ?? "",
        interviews: opening?.interviews ?? [],
      });
    }
  }, [mode, opening]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      if (openingId) {
        navigate(`/opening/${openingId}`);
      }
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data = new FormData();
    data.append("role", jobObject.role);
    data.append("scope", jobObject.scope);
    data.append("budget", jobObject.budget);
    data.append("desiredStartDate", jobObject.desiredStartDate);
    data.append(
      "fullTimeConversionPossible",
      jobObject.fullTimeConversionPossible.toString(),
    );
    data.append("matchCriteria1", jobObject.matchCriteria1);
    data.append("matchCriteria2", jobObject.matchCriteria2);
    data.append("matchCriteria3", jobObject.matchCriteria3);
    jobObject.interviews.forEach((interview, idx) => {
      if (interview.id) {
        data.append(`interviews[${idx}][id]`, interview.id);
      }

      if (interview.name) {
        data.append(`interviews[${idx}][name]`, interview.name);
      }

      if (interview.duration) {
        data.append(
          `interviews[${idx}][duration]`,
          interview.duration.toString(),
        );
      }

      interview.participants?.forEach((participant) => {
        data.append(`interviews[${idx}][participants][]`, participant);
      });
    });

    if (jobObject.jobDescription) {
      data.append("jobDescription", jobObject.jobDescription);
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      if (mode === "create") {
        void dispatch(createOpening({ data, token }));
      } else if (mode === "update" && opening?.id) {
        void dispatch(updateOpening({ id: opening.id, data, token }));
      }
    })();
  };

  const setOpening = (update: Partial<JobObject>) => {
    setJobObject({
      ...jobObject,
      ...update,
    });
  };

  return (
    <>
      {tab === "Job Details" ? (
        <JobForm opening={jobObject} update={setOpening} />
      ) : (
        <InterviewForm opening={jobObject} team={team} update={setOpening} />
      )}
      <Button appearance="primary" onClick={onSubmit} disabled={posting}>
        {posting ? "Saving..." : "Save"}
      </Button>
    </>
  );
};

const JobForm = ({
  opening,
  update,
}: {
  opening: JobObject;
  update: (update: Partial<JobObject>) => void;
}) => {
  const fieldStyle = useFieldStyles();

  return (
    <>
      <div className="flex flex-col gap-2">
        <Field label="Job Title" className={fieldStyle.editField}>
          <Input
            type="text"
            placeholder="CEO"
            value={opening.role}
            onChange={(_evt, { value }) => update({ role: value })}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2">
        <Field label="Job Description" className={fieldStyle.editField}>
          <InfoLabel
            className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
            size="small"
          >
            Please upload an editable file, such as a Word Document
          </InfoLabel>
          {opening?.jobDescriptionLink ? (
            <a
              className="cursor-pointer"
              href={opening.jobDescriptionLink}
              target="_blank"
              rel="noreferrer"
            >
              Current Job Description
            </a>
          ) : opening.jobDescription ? (
            <a
              className="cursor-pointer"
              href={URL.createObjectURL(opening.jobDescription)}
              target="_blank"
              rel="noreferrer"
            >
              Current Job Description
            </a>
          ) : null}
          <FileUploader
            classes="flex-1"
            multiple={false}
            fileOrFiles={opening.jobDescription}
            handleChange={(file?: File) => update({ jobDescription: file })}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2">
        <Field
          label="What is the scope of the engagement?"
          className={fieldStyle.editField}
        >
          <Input
            type="text"
            placeholder="2 hours a day, 2 days a week"
            value={opening.scope}
            onChange={(_evt, { value }) => update({ scope: value })}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2">
        <Field
          label="If this is not already a full-time position, would it potentially lead to one?"
          className={fieldStyle.editField}
        ></Field>
        <Checkbox
          checked={opening.fullTimeConversionPossible}
          onChange={(_evt, { checked }) =>
            update({ fullTimeConversionPossible: checked as boolean })
          }
        />
      </div>
      <div className="flex flex-col gap-2">
        <Field
          label="What is your budget for the role?"
          className={fieldStyle.editField}
        >
          <Input
            type="text"
            placeholder="$150-$225/hr"
            value={opening.budget}
            onChange={(_evt, { value }) => update({ budget: value })}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2">
        <Field
          label="What are the three most important criteria for this role?"
          className={fieldStyle.editField}
        >
          <Textarea
            value={opening.matchCriteria1}
            placeholder="Experience with healthcare SaaS and acquisitions"
            onChange={(_evt, { value }) => update({ matchCriteria1: value })}
            resize="both"
          />
          <Textarea
            value={opening.matchCriteria2}
            placeholder="Executive presence with the board"
            onChange={(_evt, { value }) => update({ matchCriteria2: value })}
            resize="both"
          />
          <Textarea
            value={opening.matchCriteria3}
            placeholder="Willingness to perform financial functions from basic to executive levels"
            onChange={(_evt, { value }) => update({ matchCriteria3: value })}
            resize="both"
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2">
        <Field
          label="What is the ideal start date?"
          className={fieldStyle.editField}
        >
          <ReactDatePicker
            className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
            selected={
              opening.desiredStartDate
                ? dateStringToLocalDate(opening.desiredStartDate)
                : null
            }
            onChange={(date) =>
              update({
                desiredStartDate: date ? localDateToDateString(date) : "",
              })
            }
            dateFormat="MM/dd/yyyy"
            showIcon
            icon={<CalendarSVG className="top-[2px]" />}
          />
        </Field>
      </div>
    </>
  );
};

const SortableItem = (props: {
  id: number;
  interview: Interview;
  onClick: () => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="w-80 flex !flex-row items-center gap-2"
      onClick={props.onClick}
    >
      <div className="h-6 w-6 flex justify-center" {...listeners}>
        <ReOrderDotsVerticalFilled className="m-auto" />
      </div>
      <div className="flex-1 flex gap-2 items-center justify-between">
        <p className="font-semibold">{props.interview.name}</p>
        <p>{props.interview.duration} mins</p>
      </div>
    </Card>
  );
};

const EditOrCreateInterview = ({
  isOpen,
  interview,
  team,
  saveFn,
  deleteFn,
  cancelFn,
}: {
  isOpen: boolean;
  interview?: Interview;
  team: TeamMember[];
  saveFn: (interview: Interview) => void;
  deleteFn: () => void;
  cancelFn: () => void;
}) => {
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("30");
  const [participants, setParticipants] = useState<string[]>([]);
  const [participantIdToName, setParticipantIdToName] = useState<
    Record<string, string>
  >({});

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    if (isOpen) {
      setName(interview?.name ?? "");
      setDuration(interview?.duration?.toString() ?? "30");
      setParticipants(
        interview?.participants?.filter((id) =>
          Object.prototype.hasOwnProperty.call(participantIdToName, id),
        ) ?? [],
      );
    }
  }, [isOpen, interview, participantIdToName]);

  useEffect(() => {
    const idToName: Record<string, string> = {};
    team.forEach(({ id, name }) => {
      if (name) {
        idToName[id] = name;
      }
    });

    setParticipantIdToName(idToName);
  }, [team]);

  const onSubmit = () => {
    saveFn({
      name,
      participants,
      duration: Number.parseInt(duration),
    });
  };

  const disabled =
    !name || Number.isNaN(Number.parseInt(duration)) || !participants.length;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => data.open || cancelFn()}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Interview Details
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Name" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={name}
                  onChange={(_evt, { value }) => setName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Duration" className={fieldStyle.editField}>
                <Input
                  type="number"
                  min={1}
                  step={1}
                  value={duration}
                  onChange={(_evt, { value }) => setDuration(value)}
                />
              </Field>
            </div>

            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Interviewer(s)" className={fieldStyle.editField}>
                <Dropdown
                  value={participants
                    .map((id) => participantIdToName[id])
                    .join(", ")}
                  selectedOptions={participants}
                  onOptionSelect={(_evt, data) =>
                    setParticipants(data.selectedOptions)
                  }
                  multiselect
                >
                  {team
                    .filter(({ name }) => name)
                    .map(({ id, name }) => (
                      <Option key={`member-${id}`} value={id}>
                        {name ?? ""}
                      </Option>
                    ))}
                </Dropdown>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex-1 flex flex-col gap-2 self-stretch items-stretch pt-4 px-6 border-top-line">
              <div className="flex gap-2 items-center self-stretch">
                <Button
                  className="flex-1 !text-white !hover:bg-red-600 hover:!bg-red-600 !bg-red-500"
                  appearance="secondary"
                  onClick={deleteFn}
                >
                  Delete
                </Button>
                <Button
                  className="flex-1"
                  appearance="primary"
                  onClick={onSubmit}
                  disabled={disabled}
                >
                  Save
                </Button>
              </div>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  className="flex-1"
                  appearance="subtle"
                  onClick={cancelFn}
                >
                  Cancel
                </Button>
              </DialogTrigger>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const InterviewForm = ({
  opening,
  update,
  team,
}: {
  opening: JobObject;
  team: TeamMember[];
  update: (update: Partial<JobObject>) => void;
}) => {
  const [selectedInterviewIndex, setSelectedInterviewIndex] = useState<
    number | undefined
  >();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent, list: Interview[]): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      // Ids are idx + 1 because ids of 0 seem to cause issues
      const oldIndex = (active.id as number) - 1;
      const newIndex = (over.id as number) - 1;

      update({
        interviews: arrayMove(list, oldIndex, newIndex),
      });
    }
  };

  const addInterview = (interview: Interview) => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    setSelectedInterviewIndex(undefined);

    update({
      interviews: [...opening.interviews, interview],
    });
  };

  const updateInterview = (interview: Partial<Interview>) => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    const newInterviews = [...opening.interviews];
    newInterviews[selectedInterviewIndex] = {
      ...newInterviews[selectedInterviewIndex],
      ...interview,
    };

    setSelectedInterviewIndex(undefined);
    update({
      interviews: newInterviews,
    });
  };

  const deleteInterview = () => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    setSelectedInterviewIndex(undefined);
    update({
      interviews: [...opening.interviews].splice(selectedInterviewIndex, 1),
    });
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => handleDragEnd(event, opening.interviews)}
      >
        <SortableContext
          items={opening.interviews.map((_, idx) => idx + 1)}
          strategy={verticalListSortingStrategy}
        >
          {opening.interviews.map((interview, idx) => (
            <SortableItem
              key={`interview-${interview.id ?? idx}`}
              // ID of 0 seems to cause issues, so increment by 1
              id={idx + 1}
              interview={interview}
              onClick={() => setSelectedInterviewIndex(idx)}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Card
        className="w-80 flex !flex-row items-center justify-center gap-2 cursor-pointer"
        onClick={() => setSelectedInterviewIndex(opening.interviews.length)}
      >
        {/* Full width plus sign */}
        <p className="font-semibold">&#65291; Add another interview</p>
      </Card>
      <EditOrCreateInterview
        isOpen={selectedInterviewIndex !== undefined}
        interview={
          selectedInterviewIndex === undefined
            ? undefined
            : opening.interviews[selectedInterviewIndex]
        }
        team={team}
        cancelFn={() => setSelectedInterviewIndex(undefined)}
        saveFn={
          selectedInterviewIndex !== undefined &&
          opening.interviews[selectedInterviewIndex]
            ? updateInterview
            : addInterview
        }
        deleteFn={deleteInterview}
      />
    </>
  );
};

export default CompanyOpportunityEdit;
