import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Skeleton,
  SkeletonItem,
  Avatar,
  Card,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogTrigger,
  DialogContent,
  DialogActions,
  Field,
  Input,
} from "@fluentui/react-components";
import { CloseCircleSVG } from "../assets/svgs";
import { useDialogStyles, useFieldStyles } from "../fluentStyles";
import {
  addUserToTeam,
  getTeam,
  removeUserFromTeam,
  resetPostingStatus,
  TeamMember,
} from "../redux/features/companyProfileSlice";

const Team = () => {
  const [dataRequested, setDataRequested] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const user = useAppSelector((state) => state.auth.user);
  const team = useAppSelector((state) => state.companyProfile.team);
  const status = useAppSelector((state) => state.companyProfile.status);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const companyId = user?.companyIds[0];

  useEffect(() => {
    setDataRequested(false);
    setLoaded(false);
  }, [companyId]);

  useEffect(() => {
    if (companyId && !dataRequested) {
      const controller = new AbortController();

      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(
          getTeam({
            id: companyId,
            token,
            signal: controller.signal,
          }),
        );
        setDataRequested(true);
      })();

      return () => {
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (!loaded && dataRequested && status === "succeeded") {
      setLoaded(true);
    }
  }, [loaded, dataRequested, status]);

  useEffect(() => {
    document.title = "Manage your team";
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5">
        <TeamMembers companyId={companyId} team={team} loaded={loaded} />
        {/* <Opportunity opening={opening} />
        <Candidates
          opening={opening}
          candidates={opening?.candidates}
          loaded={loaded}
        /> */}
      </div>
    </>
  );
};

const TeamMembers = ({
  companyId,
  team,
  loaded,
}: {
  companyId?: string;
  team?: TeamMember[];
  loaded: boolean;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setDialogState(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const setDialogState = (state: boolean) => {
    if (!posting) {
      setDialogOpen(state);
      setName("");
      setEmail("");
    }
  };

  const addMember = () => {
    if (postingStatus !== "idle" || !companyId) {
      return;
    }

    const data = {
      name,
      email,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(addUserToTeam({ companyId, data, token }));
    })();
  };

  const deleteMember = (userId: string) => {
    if (postingStatus !== "idle" || !companyId) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(removeUserFromTeam({ companyId, userId, token }));
    })();
  };

  const posting = postingStatus === "posting";
  const disableSubmit =
    !name || !email || !companyId || postingStatus !== "idle";

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-[#22234A]">Team Members</h2>
        <Button
          appearance="primary"
          disabled={posting}
          onClick={() => setDialogState(true)}
        >
          Add Member
        </Button>
      </div>
      {!loaded ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <TeamMemberTile disabled={posting} deleteFn={deleteMember} />
          <TeamMemberTile disabled={posting} deleteFn={deleteMember} />
          <TeamMemberTile disabled={posting} deleteFn={deleteMember} />
        </div>
      ) : team?.length ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          {team.map((teamMember) => (
            <TeamMemberTile
              key={`team-${teamMember.id}`}
              teamMember={teamMember}
              disabled={posting}
              deleteFn={deleteMember}
            />
          ))}
        </div>
      ) : (
        <Card>
          <p className="text-base font-medium text-[#727E94]">
            This organization has no team members assigned to it
          </p>
        </Card>
      )}
      <Dialog
        open={dialogOpen}
        onOpenChange={(_evt, data) => setDialogState(data.open)}
      >
        <DialogSurface className={dialogStyle.editDialog}>
          <DialogBody>
            <DialogTitle>
              <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
                <p className="text-lg font-semibold tracking-[-0.18px]">
                  Add Member To Team
                </p>
                <DialogTrigger disableButtonEnhancement>
                  <CloseCircleSVG
                    className="cursor-pointer"
                    height="20"
                    width="20"
                  />
                </DialogTrigger>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col gap-2 self-stretch items-stretch">
                <Field label="Name" className={fieldStyle.editField}>
                  <Input
                    type="text"
                    placeholder="John Doe"
                    value={name}
                    onChange={(_evt, { value }) => setName(value)}
                  />
                </Field>
              </div>
              <div className="flex flex-col gap-2 self-stretch items-stretch">
                <Field label="Email" className={fieldStyle.editField}>
                  <Input
                    type="email"
                    placeholder="john.doe@company.com"
                    value={email}
                    onChange={(_evt, { value }) => setEmail(value)}
                  />
                </Field>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
                <DialogTrigger disableButtonEnhancement>
                  <Button className="flex-1" appearance="secondary">
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button
                  className="flex-1"
                  appearance="primary"
                  disabled={disableSubmit}
                  onClick={addMember}
                >
                  {posting ? "Adding..." : "Add Team Member"}
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

const TeamMemberTile = ({
  teamMember,
  disabled,
  deleteFn,
}: {
  teamMember?: TeamMember;
  disabled: boolean;
  deleteFn: (id: string) => void;
}) => {
  if (!teamMember) {
    return (
      <Card>
        <div className="flex flex-col gap-3 w-full">
          <div className="flex-1 flex gap-3">
            <Skeleton className="h-12 w-12">
              <SkeletonItem size={48} shape="circle" />
            </Skeleton>
            <div className="flex flex-col gap-[2px] items-start justify-between">
              <Skeleton className="w-40">
                <SkeletonItem size={24} className="w-full" />
              </Skeleton>
              <Skeleton className="w-40">
                <SkeletonItem size={16} className="w-full" />
              </Skeleton>
            </div>
          </div>
          <div className="w-full">
            <Skeleton className="float-right h-8 w-24">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="flex flex-col gap-3 w-full">
        <div className="flex-1 flex gap-3">
          <div className="flex flex-col items-start rounded-full relative">
            <Avatar
              image={{ src: teamMember.profilePictureLink }}
              name={teamMember.name}
              size={48}
            />
          </div>
          <div className="flex flex-col items-start gap-1 flex-1">
            <div className="flex flex-col gap-[2px] items-start">
              <p className="text-base font-semibold text-[#22234A]">
                {teamMember.name}
              </p>
              <p className="text-sm font-normal text-[#727E94]">
                {teamMember.emails[0]}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <Button
            className="float-right"
            appearance="primary"
            disabled={disabled}
            onClick={() => deleteFn(teamMember.id)}
          >
            Remove
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Team;
