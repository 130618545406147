import { PropsWithChildren } from "react";

export const Button = (
  props: PropsWithChildren<{
    type: "primary" | "secondary";
    onClick: () => void;
    disabled?: boolean;
  }>,
) => {
  let text, background, hover;
  if (props.type === "primary") {
    text = "text-white";
    background = "bg-[#D23B15]";
    hover = "hover:bg-[#BD3513]";
  } else if (props.type === "secondary") {
    text = "text-[#4E4F6C]";
    background = "bg-[#F2F2F2]";
    hover = "hover:bg-[#E2E2EA]";
  }

  return (
    <button
      className={`flex gap-2 justify-center content-center items-center self-stretch rounded-full border-none ${text} py-[10px] px-4 focus:outline-none ${props.disabled ? "cursor-not-allowed bg-[#BDBDBD] hover:bg[#BDBDBD]" : `cursor-pointer ${background} ${hover}`}`}
      onClick={() => (props.disabled ? undefined : props.onClick())}
    >
      {props.children}
    </button>
  );
};
