import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addCandidateExperience,
  CompanyEmployees,
  companyEmployeesList,
  deleteCandidateExperience,
  EngagementType,
  engagementTypeList,
  FunctionArea,
  functionAreaList,
  getCandidateProfile,
  HealthcareIndustry,
  healthcareIndustryList,
  IExperience,
  Industry,
  industryList,
  resetPostingStatus,
  Role,
  roleList,
  Skills,
  Stage,
  stageList,
  updateCandidateExperience,
  updateCandidateProfile,
  UseCase,
  useCaseList,
} from "../redux/features/candidateProfileSlice";
import {
  Dropdown,
  Field,
  Option as FluentOption,
  InfoLabel,
  Input,
  OptionGroup,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import {
  AddSVG,
  ArrowLeftSVG,
  ArrowRightSVG,
  CalendarSVG,
  Edit2SVG,
  EllipseSVG,
  LocationSVG,
  TrashSVG,
  VenturousSVG,
} from "../assets/svgs";
import { useFieldStyles, useInputStyles } from "../fluentStyles";
import { Button } from "../elements/Button";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import { FileUploader } from "react-drag-drop-files";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { completeOnboarding } from "../redux/features/authSlice";
import ReactDatePicker from "react-datepicker";
import localDateToDateString from "../utils/localDateToDateString";
import generateSkillOptionsLists from "../utils/generateSkillOptionsList";

const STAGES = [
  "Ideal Role",
  "Core Competencies",
  "Experience",
  "Fractional Experience",
  "Activate",
] as const;

interface SkillsPayload {
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
}

const Onboarding = () => {
  const [dataRequested, setDataRequested] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const dataStatus = useAppSelector((state) => state.candidateProfile.status);

  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const loading = !dataRequested || dataStatus !== "succeeded";

  useEffect(() => {
    if (user?.onboarded) {
      navigate("/profile/edit");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  useEffect(() => {
    const controller = new AbortController();
    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(
        getCandidateProfile({
          id: user?.id ?? "",
          token,
          signal: controller.signal,
        }),
      );
      setDataRequested(true);
    })();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Venturous Profile";
  }, []);

  if (loading) {
    return (
      <div className="h-full flex flex-col gap-8 justify-center items-center">
        <Spinner size="huge" />
        <div className="flex flex-col gap-1 text-center">
          <p className="text-2xl leading-6 font-semibold tracking-[-0.18px] text-[#22234A]">
            Welcome to Venturous
          </p>
          <p className="text-base font-medium text-[#727E94]">
            We&apos;re fetching your information...
          </p>
        </div>
      </div>
    );
  }

  return <OnboardingContent />;
};

const OnboardingContent = () => {
  const [currentStage, setCurrentStage] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [activating, setActivating] = useState(false);

  const [idealRoleObject, setIdealRoleObject] = useState<{
    idealRoles: Role[];
    idealSubindustries: HealthcareIndustry[];
    idealCompanyHeadcount: CompanyEmployees[];
    idealUseCase: UseCase[];
  }>({
    idealRoles: [],
    idealSubindustries: [],
    idealCompanyHeadcount: [],
    idealUseCase: [],
  });
  const [skillsObject, setSkillsObject] = useState<SkillsPayload>({
    topSkills: [],
    secondarySkills: [],
    additionalSkills: [],
  });
  const [workExperienceList, setWorkExperienceList] = useState<IExperience[]>(
    [],
  );
  const [header, setHeader] = useState<{
    profilePictureLink: string;
    newProfilePicture?: File;
    titleAndEmployer: string;
    yearsOfExperience: string;
    location: string;
  }>({
    profilePictureLink: "",
    titleAndEmployer: "",
    yearsOfExperience: "",
    location: "",
  });

  const profile = useAppSelector((state) => state.candidateProfile.candidate);
  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const userId = useAppSelector((state) => state.auth.user?.id) ?? "";
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setIdealRoleObject({
      idealRoles: [...profile.idealRoles],
      idealSubindustries: [...profile.idealSubindustries],
      idealCompanyHeadcount: [...profile.idealCompanyHeadcount],
      idealUseCase: profile.idealUseCase,
    });
    setSkillsObject({
      topSkills: [...profile.topSkills],
      secondarySkills: [...profile.secondarySkills],
      additionalSkills: [...profile.additionalSkills],
    });
    setWorkExperienceList(
      profile.experiences.map((experience) => ({
        ...experience,
      })),
    );
    setHeader({
      profilePictureLink: profile.profilePictureLink ?? "",
      titleAndEmployer: profile.titleAndEmployer ?? "",
      yearsOfExperience: `${profile.yearsOfExperience ?? ""}`,
      location: profile.location ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage]);

  const onSubmitIdealRole = () => {
    const data = {
      emails,
      ...idealRoleObject,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: userId, data, token }));
    })();
  };

  const onSubmitSkills = () => {
    const data = {
      ...skillsObject,
      emails,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: userId, data, token }));
    })();
  };

  const onSubmitHeadline = () => {
    const data = new FormData();
    emails.forEach((email) => data.append("emails[]", email));
    data.append("titleAndEmployer", header.titleAndEmployer);
    data.append("location", header.location);
    data.append("yearsOfExperience", header.yearsOfExperience);

    if (header.newProfilePicture) {
      data.append("profilePicture", header.newProfilePicture);
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(updateCandidateProfile({ id: userId, data, token }));
      await dispatch(completeOnboarding(token));
    })();
  };

  let page;
  switch (currentStage) {
    case 0:
      page = (
        <IdealRole
          idealRoleObject={idealRoleObject}
          setIdealRoleObject={setIdealRoleObject}
          nextFn={() => {
            onSubmitIdealRole();
            setCurrentStage(1);
          }}
        />
      );
      break;
    case 1:
      page = (
        <GreatestSkills
          roles={idealRoleObject.idealRoles}
          skills={skillsObject}
          setSkillsFn={setSkillsObject}
          nextFn={() => {
            onSubmitSkills();
            setCurrentStage(workExperienceList.length ? 2 : 3);
          }}
        />
      );
      break;
    case 2:
      page = (
        <PreviousExperience
          workExperienceList={workExperienceList}
          setWorkExperienceList={setWorkExperienceList}
          nextFn={() => setCurrentStage(3)}
        />
      );
      break;
    case 3:
      page = (
        <FractionalExperience
          workExperienceList={workExperienceList}
          nextFn={() => setCurrentStage(4)}
        />
      );
      break;
    case 4:
      page = (
        <Activate
          header={header}
          setHeader={setHeader}
          nextFn={() => {
            setActivating(true);
            onSubmitHeadline();
          }}
        />
      );
      break;
  }

  if (activating) {
    return (
      <div className="min-h-full flex flex-col">
        <div className="flex-1 flex flex-col">
          <div className="h-[72px] py-4 px-[40px] flex gap-1 items-center">
            <VenturousSVG />
            <p className="font-logo text-[37.894px] tracking-[-1.894px] text-[#19A594] cursor-default">
              ENTUROUS
            </p>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8">
            <Spinner size="huge" />
            <div className="flex flex-col gap-1 items-stretch text-center">
              <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                Your profile is generating...
              </p>
              <p className="text-sm font-medium text-[#727E94]">
                Just a few seconds left
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TODO: Add side panel
  return (
    <div className="flex min-h-full">
      <div className="flex-1 flex flex-col gap-10">
        <div className="h-[72px] py-4 px-[40px] flex gap-1 items-center">
          <VenturousSVG />
          <p className="font-logo text-[37.894px] tracking-[-1.894px] text-[#19A594] cursor-default">
            ENTUROUS
          </p>
        </div>
        <div className="flex flex-col items-center gap-8 px-[92px]">
          <ProgressTracker currentStage={currentStage} />
          {page}
        </div>
      </div>
      {/* <div className="flex-1 hidden xl:flex flex-col my-6 mr-5 rounded-2xl bg-[#003C32]"></div> */}
    </div>
  );
};

const IdealRole = ({
  idealRoleObject,
  setIdealRoleObject,
  nextFn,
}: {
  idealRoleObject: {
    idealRoles: Role[];
    idealSubindustries: HealthcareIndustry[];
    idealCompanyHeadcount: CompanyEmployees[];
    idealUseCase: UseCase[];
  };
  setIdealRoleObject: React.Dispatch<
    React.SetStateAction<{
      idealRoles: Role[];
      idealSubindustries: HealthcareIndustry[];
      idealCompanyHeadcount: CompanyEmployees[];
      idealUseCase: UseCase[];
    }>
  >;
  nextFn: () => void;
}) => {
  const fieldStyle = useFieldStyles();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent, list: Role[]): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = list.indexOf(active.id as Role);
      const newIndex = list.indexOf(over.id as Role);

      setIdealRoleObject({
        ...idealRoleObject,
        idealRoles: arrayMove(list, oldIndex, newIndex),
      });
    }
  };

  const industryOptionGenerator = (industry: HealthcareIndustry) => (
    <FluentOption
      key={`industry-${industry}`}
      disabled={
        idealRoleObject.idealSubindustries.length >= 5 &&
        !idealRoleObject.idealSubindustries.includes(industry)
      }
    >
      {industry}
    </FluentOption>
  );

  return (
    <>
      <div className="w-full flex flex-col gap-1 items-center self-start text-center">
        <p className="self-stretch text-lg font-semibold leading-6 tracking-[-0.18px] text-[#22234A]">
          Welcome to Venturous
        </p>
        <p className="self-stretch text-sm font-medium leading-5 text-[#727E94]">
          Please verify the information you shared with us about your ideal
          engagement type.
        </p>
      </div>
      <div className="w-full max-w-[450px] flex flex-col items-center gap-5">
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={
              <div className="flex flex-col gap-1">
                Ideal Roles
                <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                  Choose up to 3
                </p>
              </div>
            }
            className={fieldStyle.editField}
          >
            <Dropdown
              value={idealRoleObject.idealRoles.join(", ")}
              selectedOptions={idealRoleObject.idealRoles}
              onOptionSelect={(_evt, data) =>
                setIdealRoleObject({
                  ...idealRoleObject,
                  idealRoles: data.selectedOptions as Role[],
                })
              }
              multiselect
            >
              {roleList.map((role) => (
                <FluentOption
                  key={`role-${role}`}
                  disabled={
                    !idealRoleObject.idealRoles.includes(role) &&
                    idealRoleObject.idealRoles.length >= 3
                  }
                >
                  {role}
                </FluentOption>
              ))}
            </Dropdown>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={
              <div className="flex flex-col gap-1">
                Ranked Ideal Roles
                <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                  Drag to re-order
                </p>
              </div>
            }
            className={fieldStyle.editField}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={(event) =>
                handleDragEnd(event, idealRoleObject.idealRoles)
              }
            >
              <SortableContext
                items={idealRoleObject.idealRoles}
                strategy={verticalListSortingStrategy}
              >
                {idealRoleObject.idealRoles.map((id, idx) => (
                  <SortableItem key={id} id={id} idx={idx} />
                ))}
              </SortableContext>
            </DndContext>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={
              <div className="flex flex-col gap-1">
                Ideal Healthcare Industry
                <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                  Choose up to 5
                </p>
              </div>
            }
            className={fieldStyle.editField}
          >
            <Dropdown
              selectedOptions={idealRoleObject.idealSubindustries}
              value={idealRoleObject.idealSubindustries.join(", ")}
              onOptionSelect={(_evt, data) =>
                setIdealRoleObject({
                  ...idealRoleObject,
                  idealSubindustries:
                    data.selectedOptions as HealthcareIndustry[],
                })
              }
              multiselect
            >
              <OptionGroup label="Clinical Services">
                {healthcareIndustryList
                  .slice(0, 5)
                  .map(industryOptionGenerator)}
              </OptionGroup>
              <OptionGroup label="Technology and Data">
                {healthcareIndustryList
                  .slice(5, 10)
                  .map(industryOptionGenerator)}
              </OptionGroup>
              <OptionGroup label="Specialty Care">
                {healthcareIndustryList
                  .slice(10, 16)
                  .map(industryOptionGenerator)}
              </OptionGroup>
              <OptionGroup label="Healthcare Administration">
                {healthcareIndustryList
                  .slice(16, 20)
                  .map(industryOptionGenerator)}
              </OptionGroup>
              <OptionGroup label="Management and Engagement">
                {healthcareIndustryList
                  .slice(20, 24)
                  .map(industryOptionGenerator)}
              </OptionGroup>
            </Dropdown>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={
              <div className="flex flex-col gap-1">
                Ideal Company Size
                <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                  Choose up to 3
                </p>
              </div>
            }
            className={fieldStyle.editField}
          >
            <Dropdown
              selectedOptions={idealRoleObject.idealCompanyHeadcount}
              value={idealRoleObject.idealCompanyHeadcount.join(", ")}
              onOptionSelect={(_evt, data) =>
                setIdealRoleObject({
                  ...idealRoleObject,
                  idealCompanyHeadcount:
                    data.selectedOptions as CompanyEmployees[],
                })
              }
              multiselect
            >
              {companyEmployeesList.map((size) => (
                <FluentOption
                  key={`size-${size}`}
                  disabled={
                    idealRoleObject.idealCompanyHeadcount.length >= 3 &&
                    !idealRoleObject.idealCompanyHeadcount.includes(size)
                  }
                >
                  {size}
                </FluentOption>
              ))}
            </Dropdown>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={
              <div className="flex flex-col gap-1">
                Ideal Use Case
                <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                  Choose up to 3
                </p>
              </div>
            }
            className={fieldStyle.editField}
          >
            <Dropdown
              selectedOptions={idealRoleObject.idealUseCase}
              value={idealRoleObject.idealUseCase.join(", ")}
              onOptionSelect={(_evt, data) =>
                setIdealRoleObject({
                  ...idealRoleObject,
                  idealUseCase: data.selectedOptions as UseCase[],
                })
              }
              multiselect
            >
              {useCaseList.map((useCase) => (
                <FluentOption
                  key={`use-case-${useCase}`}
                  disabled={
                    idealRoleObject.idealUseCase.length >= 3 &&
                    !idealRoleObject.idealUseCase.includes(useCase)
                  }
                >
                  {useCase}
                </FluentOption>
              ))}
            </Dropdown>
          </Field>
        </div>
        <Button type="primary" onClick={nextFn}>
          Next
          <ArrowRightSVG height="16" width="16" stroke="white" />
        </Button>
      </div>
    </>
  );
};

const GreatestSkills = ({
  roles,
  skills,
  setSkillsFn,
  nextFn,
}: {
  roles: Role[];
  skills: SkillsPayload;
  setSkillsFn: React.Dispatch<React.SetStateAction<SkillsPayload>>;
  nextFn: () => void;
}) => {
  const { topSkills, secondarySkills, additionalSkills } = skills;

  const fieldStyle = useFieldStyles();

  const { options: topSkillsOptions, skillsList } = generateSkillOptionsLists({
    roles,
    list: "top",
    topSkills,
    secondarySkills,
    additionalSkills,
    maxSelections: 5,
  });

  return (
    <>
      <div className="w-full flex flex-col gap-1 items-center self-start text-center">
        <p className="self-stretch text-lg font-semibold leading-6 tracking-[-0.18px] text-[#22234A]">
          Welcome to Venturous
        </p>
        <p className="self-stretch text-sm font-medium leading-5 text-[#727E94]">
          Please tell us about the skills you&apos;ve developed over your
          career.
        </p>
      </div>
      <div className="w-full max-w-[450px] flex flex-col items-center gap-5">
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={`Choose your 5 top skills out of ${skillsList.length} `}
            className={fieldStyle.editField}
          >
            <Dropdown
              value={topSkills.join(", ")}
              selectedOptions={topSkills ?? ""}
              onOptionSelect={(_evt, data) =>
                setSkillsFn({
                  topSkills: data.selectedOptions as Skills[],
                  secondarySkills: secondarySkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                  additionalSkills: additionalSkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                })
              }
              multiselect
            >
              {topSkillsOptions}
            </Dropdown>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={`Choose your 5 secondary skills out of ${skillsList.length} `}
            className={fieldStyle.editField}
          >
            <Dropdown
              value={secondarySkills.join(", ")}
              selectedOptions={secondarySkills ?? ""}
              onOptionSelect={(_evt, data) =>
                setSkillsFn({
                  topSkills: topSkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                  secondarySkills: data.selectedOptions as Skills[],
                  additionalSkills: additionalSkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                })
              }
              multiselect
            >
              {
                generateSkillOptionsLists({
                  roles,
                  list: "secondary",
                  topSkills,
                  secondarySkills,
                  additionalSkills,
                  maxSelections: 5,
                }).options
              }
            </Dropdown>
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field
            label={`Choose your 10 additional skills out of ${skillsList.length} `}
            className={fieldStyle.editField}
          >
            <Dropdown
              value={additionalSkills.join(", ")}
              selectedOptions={additionalSkills ?? ""}
              onOptionSelect={(_evt, data) =>
                setSkillsFn({
                  topSkills: topSkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                  secondarySkills: secondarySkills.filter(
                    (skill) => !data.selectedOptions.includes(skill),
                  ),
                  additionalSkills: data.selectedOptions as Skills[],
                })
              }
              multiselect
            >
              {
                generateSkillOptionsLists({
                  roles,
                  list: "additional",
                  topSkills,
                  secondarySkills,
                  additionalSkills,
                  maxSelections: 10,
                }).options
              }
            </Dropdown>
          </Field>
        </div>
        <Button type="primary" onClick={nextFn}>
          Next
          <ArrowRightSVG height="16" width="16" stroke="white" />
        </Button>
      </div>
    </>
  );
};

const PreviousExperience = ({
  workExperienceList,
  setWorkExperienceList,
  nextFn,
}: {
  workExperienceList: IExperience[];
  setWorkExperienceList: React.Dispatch<React.SetStateAction<IExperience[]>>;
  nextFn: () => void;
}) => {
  const [experienceIndex, setExperienceIndex] = useState(0);
  const [changesMade, setChangesMade] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setChangesMade(false);
  }, [workExperienceList.length, experienceIndex, setChangesMade]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [experienceIndex]);

  const experience = workExperienceList[experienceIndex];

  if (!experience) {
    nextFn();
    return;
  }

  const updateExperience = (update: Partial<IExperience>) => {
    const updatedList = [...workExperienceList];
    updatedList[experienceIndex] = {
      ...experience,
      ...update,
    };

    setChangesMade(true);
    setWorkExperienceList(updatedList);
  };

  const update = () => {
    const data = {
      companyName: experience.companyName,
      companyDescription: experience.companyDescription,
      industries: experience.industries,
      otherIndustries: experience.otherIndustries,
      stage: experience.stage,
      jobTitle: experience.jobTitle,
      engagementType: experience.engagementType,
      functions: experience.functions,
      location: experience.location,
      startDate: experience.startDate,
      endDate: experience.endDate,
      outcomes: experience.outcomes,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(
        updateCandidateExperience({ id: experience.id!, data, token }),
      );
    })();
  };

  const deleteExperience = () => {
    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(deleteCandidateExperience({ id: experience.id!, token }));
      setExperienceIndex(experienceIndex + 1);
    })();
  };

  const nextExperience = () => {
    if (changesMade) {
      update();
    }
    setExperienceIndex(experienceIndex + 1);
  };

  return (
    <>
      <div className="w-full flex flex-col gap-1 items-center self-start text-center">
        <p className="self-stretch text-lg font-semibold leading-6 tracking-[-0.18px] text-[#22234A]">
          Work experience {experienceIndex + 1} of {workExperienceList.length}
        </p>
        <div className="w-full max-w-[300px] flex gap-1 items-start">
          {workExperienceList.map((_, idx) => (
            <div
              key={`experience-tracker-${idx}`}
              className={`flex-1 h-1 rounded-full ${idx <= experienceIndex ? "bg-[#19A594]" : "bg-[#F2F2F2]"}`}
            />
          ))}
        </div>
        <p className="self-stretch text-sm font-medium leading-5 text-[#727E94]">
          Please verify the information about your previous work experiences.
        </p>
      </div>
      <ExperienceEntry
        experience={experience}
        changesMade={changesMade}
        updateExperience={updateExperience}
        deleteExperience={deleteExperience}
        nextFn={nextExperience}
      />
    </>
  );
};

const FractionalExperience = ({
  workExperienceList,
  nextFn,
}: {
  workExperienceList: IExperience[];
  nextFn: () => void;
}) => {
  const [addExperience, setAddExperience] = useState(false);

  return (
    <>
      <div className="w-full max-w-[550px] flex flex-col gap-1 self-center items-center text-center">
        <p className="self-stretch text-lg font-semibold leading-6 tracking-[-0.18px] text-[#22234A]">
          Add Fractional and Consulting Experience
        </p>
        <p className="self-stretch text-sm font-medium leading-5 text-[#727E94]">
          Please add any <b>fractional healthcare experiences</b> from
          approximately the last 10 years that were not listed previously to
          help us improve your matches.
        </p>
      </div>
      {addExperience ? (
        <AddFractionalExperience cancelFn={() => setAddExperience(false)} />
      ) : (
        <div className="w-full max-w-[450px] flex flex-col gap-3 items-center">
          <Button type="secondary" onClick={() => setAddExperience(true)}>
            <AddSVG height="16" width="16" stroke="#4E4F6C" />
            Add Experience
          </Button>
          {workExperienceList.length ? (
            <Button type="primary" onClick={nextFn}>
              Next
              <ArrowRightSVG height="16" width="16" stroke="white" />
            </Button>
          ) : (
            <Button type="secondary" onClick={nextFn}>
              Skip for now
            </Button>
          )}
        </div>
      )}
    </>
  );
};

const AddFractionalExperience = ({ cancelFn }: { cancelFn: () => void }) => {
  const userId = useAppSelector((state) => state.auth.user?.id) ?? "";

  const [newCompanyName, setNewCompanyName] = useState("");
  const [newCompanyDescription, setNewCompanyDescription] = useState("");
  const [newIndustries, setNewIndustries] = useState<Industry | "">("");
  const [newOtherIndustries, setNewOtherIndustries] = useState("");
  const [newStage, setNewStage] = useState<Stage | "">("");
  const [newJobTitle, setNewJobTitle] = useState("");
  const [newEngagementType, setNewEngagementType] =
    useState<EngagementType>("Part-Time");
  const [newFunctions, setNewFunctions] = useState<FunctionArea[]>([]);
  const [newLocation, setNewLocation] = useState("");
  const [newStartDate, setNewStartDate] = useState<Date | null>(null);
  const [newEndDate, setNewEndDate] = useState<Date | null>(null);
  const [newOutcomes, setNewOutcomes] = useState("");

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  const onSubmit = () => {
    const data = new FormData();
    data.append("companyName", newCompanyName);
    data.append("companyDescription", newCompanyDescription);
    data.append("industries", newIndustries);
    data.append("otherIndustries", newOtherIndustries);
    data.append("stage", newStage);
    data.append("jobTitle", newJobTitle);
    data.append("engagementType", newEngagementType);
    newFunctions.forEach((func) => data.append("functions[]", func));
    data.append("location", newLocation);
    data.append(
      "startDate",
      newStartDate ? localDateToDateString(newStartDate) : "",
    );
    data.append("endDate", newEndDate ? localDateToDateString(newEndDate) : "");
    data.append("outcomes", newOutcomes);

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(addCandidateExperience({ id: userId, data, token }));
    })();

    cancelFn();
  };

  return (
    <div className="w-full max-w-[550px] p-4 flex gap-4 flex-col items-start border border-solid border-[#F2F2F2] rounded-xl">
      <div
        className="flex gap-2 items-center cursor-pointer p-1 rounded hover:bg-[#F2F2F2]"
        onClick={cancelFn}
      >
        <ArrowLeftSVG height="20" width="20" stroke="#4E4F6C" />
        <p className="textsm font-semibold text-[#4E4F6C]">Back</p>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field label="Position" className={fieldStyle.editField}>
          <Input
            type="text"
            placeholder="CEO"
            value={newJobTitle}
            onChange={(_evt, { value }) => setNewJobTitle(value)}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field label="Company" className={fieldStyle.editField}>
          <Input
            type="text"
            placeholder="Microsoft"
            value={newCompanyName}
            onChange={(_evt, { value }) => setNewCompanyName(value)}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field label="Engagement Type" className={fieldStyle.editField}>
          <Dropdown
            value={newEngagementType}
            onOptionSelect={(_evt, data) =>
              setNewEngagementType(data.selectedOptions[0] as EngagementType)
            }
          >
            <FluentOption>Part-Time</FluentOption>
            <FluentOption>Full-Time</FluentOption>
            <FluentOption>Consultant</FluentOption>
            <FluentOption>Advisor</FluentOption>
            <FluentOption>Board</FluentOption>
          </Dropdown>
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field label="Function" className={fieldStyle.editField}>
          <Dropdown
            value={newFunctions.join(", ")}
            selectedOptions={newFunctions}
            onOptionSelect={(_evt, data) => {
              if (
                data.selectedOptions.length !== newFunctions.length ||
                newFunctions.some(
                  (option) => !data.selectedOptions.includes(option),
                )
              ) {
                setNewFunctions(data.selectedOptions as FunctionArea[]);
              }
            }}
          >
            {functionAreaList.map((area) => (
              <FluentOption key={`function-${area}`}>{area}</FluentOption>
            ))}
          </Dropdown>
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field label="Location" className={fieldStyle.editField}>
          <Input
            type="text"
            placeholder="New York, NY"
            value={newLocation}
            onChange={(_evt, { value }) => setNewLocation(value)}
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="Date"
          hint="Leave the second date blank if this engagement is ongoing"
          className={fieldStyle.editField}
        >
          <div className="flex flex-row gap-2 justify-between">
            <ReactDatePicker
              className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
              selected={newStartDate}
              onChange={(date) => setNewStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showIcon
              icon={<CalendarSVG className="top-[2px]" />}
            />
            <p className="m-auto">&mdash;</p>
            <ReactDatePicker
              className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
              selected={newEndDate}
              onChange={(date) => setNewEndDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showIcon
              icon={<CalendarSVG className="top-[2px]" />}
            />
          </div>
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="Responsibilities and Outcomes"
          className={fieldStyle.editField}
        >
          <Textarea
            className={inputStyle.largeTextarea}
            value={newOutcomes}
            onChange={(_evt, { value }) => setNewOutcomes(value)}
            resize="vertical"
          />
        </Field>
      </div>
      <div className="w-full h-[1px] min-h-[1px] bg-[#F2F2F2]" />
      <p className="text-sm font-semibold text-[#22234A]">
        More about your role & company
      </p>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What does the company do?"
          className={fieldStyle.editField}
        >
          <Textarea
            placeholder="Describe it in one or two sentences"
            value={newCompanyDescription}
            onChange={(_evt, data) => setNewCompanyDescription(data.value)}
            resize="vertical"
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What industry does the company operate in?"
          className={fieldStyle.editField}
        >
          <InfoLabel
            className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
            size="small"
            info={
              <p>
                If the company operates in multiple industries, choose the
                primary one.
              </p>
            }
          >
            Choose 1 industry from the list
          </InfoLabel>
          <Dropdown
            value={newIndustries}
            selectedOptions={[newIndustries]}
            onOptionSelect={(_evt, data) => {
              const option = data.optionValue as Industry;
              setNewIndustries(option);

              if (option !== "Other") {
                setNewOtherIndustries("");
              }
            }}
          >
            {industryList.map((option) => (
              <FluentOption key={`industry-${option}`}>{option}</FluentOption>
            ))}
          </Dropdown>
          {newIndustries === "Other" ? (
            <Input
              type="text"
              placeholder="Other industry"
              value={newOtherIndustries}
              onChange={(_evt, { value }) => setNewOtherIndustries(value)}
            />
          ) : null}
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What stage was the company at when you hired?"
          className={fieldStyle.editField}
        >
          <Dropdown
            value={newStage}
            selectedOptions={[newStage]}
            onOptionSelect={(_evt, data) =>
              setNewStage(data.optionValue as Stage)
            }
          >
            {stageList.map((stage) => (
              <FluentOption key={`stage-${stage}`}>{stage}</FluentOption>
            ))}
          </Dropdown>
        </Field>
      </div>
      <Button type="primary" onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

const Activate = ({
  header,
  setHeader,
  nextFn,
}: {
  header: {
    profilePictureLink: string;
    newProfilePicture?: File;
    titleAndEmployer: string;
    yearsOfExperience: string;
    location: string;
  };
  setHeader: React.Dispatch<
    React.SetStateAction<{
      profilePictureLink: string;
      newProfilePicture?: File;
      titleAndEmployer: string;
      yearsOfExperience: string;
      location: string;
    }>
  >;
  nextFn: () => void;
}) => {
  const fieldStyle = useFieldStyles();

  return (
    <>
      <div className="w-full max-w-[550px] flex flex-col gap-1 items-center self-center text-center">
        <p className="self-stretch text-lg font-semibold leading-6 tracking-[-0.18px] text-[#22234A]">
          Add some more information
        </p>
        <p className="self-stretch text-sm font-medium leading-5 text-[#727E94]">
          Complete your profile with a picture and a headline.
        </p>
      </div>
      <div className="w-full max-w-[550px] flex flex-col self-center items-center gap-8">
        <div className="flex flex-col gap-5 self-stretch">
          <Field label="Profile Picture" className={fieldStyle.editField}>
            <div
              className="flex w-[100px] h-[100px] self-center justify-center items-center border-slate border-solid border-2"
              style={{
                backgroundImage: `url(${header.newProfilePicture ? URL.createObjectURL(header.newProfilePicture) : header.profilePictureLink})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <FileUploader
              classes="flex-1 !max-w-full w-full"
              multiple={false}
              handleChange={(file?: File) =>
                setHeader({
                  ...header,
                  newProfilePicture: file,
                })
              }
            />
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field label="Location" className={fieldStyle.editField}>
            <Input
              type="text"
              value={header.location}
              onChange={(_evt, { value }) =>
                setHeader({
                  ...header,
                  location: value,
                })
              }
            />
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field label="Headline" className={fieldStyle.editField}>
            <Input
              type="text"
              placeholder="Ex-Microsoft CEO"
              value={header.titleAndEmployer}
              onChange={(_evt, { value }) =>
                setHeader({
                  ...header,
                  titleAndEmployer: value,
                })
              }
            />
          </Field>
        </div>
        <div className="flex flex-col gap-2 self-stretch items-stretch">
          <Field label="Years of Experience" className={fieldStyle.editField}>
            <Input
              type="number"
              min={0}
              value={header.yearsOfExperience}
              onChange={(_evt, { value }) =>
                setHeader({
                  ...header,
                  yearsOfExperience: value,
                })
              }
            />
          </Field>
        </div>
        <Button type="primary" onClick={nextFn}>
          Next
          <ArrowRightSVG height="16" width="16" stroke="white" />
        </Button>
      </div>
    </>
  );
};

const SortableItem = (props: { key: string; id: string; idx: number }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex items-center"
    >
      <p>
        {props.idx + 1}. {props.id}
      </p>
    </div>
  );
};

const ExperienceEntry = ({
  experience,
  changesMade,
  updateExperience,
  deleteExperience,
  nextFn,
}: {
  experience: IExperience;
  changesMade: boolean;
  updateExperience: (arg0: Partial<IExperience>) => void;
  deleteExperience: () => void;
  nextFn: () => void;
}) => {
  const [editMode, setEditMode] = useState(false);

  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  const startString = experience.startDate
    ? dateStringToLocalDate(experience.startDate)?.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }) ?? "Unknown"
    : "Unknown";
  const endString = experience.endDate
    ? dateStringToLocalDate(experience.endDate)?.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }) ?? "Unknown"
    : "Present";

  return (
    <div className="w-full max-w-[550px] p-4 flex gap-4 flex-col items-start border border-solid border-[#F2F2F2] rounded-xl">
      {editMode ? (
        <>
          <div
            className="flex gap-2 items-center cursor-pointer p-1 rounded hover:bg-[#F2F2F2]"
            onClick={() => setEditMode(false)}
          >
            <ArrowLeftSVG height="20" width="20" stroke="#4E4F6C" />
            <p className="textsm font-semibold text-[#4E4F6C]">Back</p>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field label="Position" className={fieldStyle.editField}>
              <Input
                type="text"
                placeholder="CEO"
                value={experience.jobTitle ?? ""}
                onChange={(_evt, { value }) =>
                  updateExperience({
                    jobTitle: value,
                  })
                }
              />
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field label="Company" className={fieldStyle.editField}>
              <Input
                type="text"
                placeholder="Microsoft"
                value={experience.companyName ?? ""}
                onChange={(_evt, { value }) =>
                  updateExperience({
                    companyName: value,
                  })
                }
              />
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field label="Engagement Type" className={fieldStyle.editField}>
              <Dropdown
                value={experience.engagementType ?? ""}
                selectedOptions={[experience.engagementType ?? ""]}
                onOptionSelect={(_evt, data) =>
                  updateExperience({
                    engagementType: data.optionValue as EngagementType,
                  })
                }
              >
                <FluentOption>Part-Time</FluentOption>
                <FluentOption>Full-Time</FluentOption>
                <FluentOption>Consultant</FluentOption>
                <FluentOption>Advisor</FluentOption>
                <FluentOption>Board</FluentOption>
              </Dropdown>
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field label="Function" className={fieldStyle.editField}>
              <Dropdown
                value={experience.functions?.join(", ") ?? ""}
                selectedOptions={experience?.functions ?? []}
                onOptionSelect={(_evt, data) => {
                  if (
                    data.selectedOptions.length !==
                      experience.functions?.length ||
                    experience.functions.some(
                      (option) => !data.selectedOptions.includes(option),
                    )
                  ) {
                    updateExperience({
                      functions: data.selectedOptions as FunctionArea[],
                    });
                  }
                }}
              >
                {functionAreaList.map((area) => (
                  <FluentOption key={`function-${area}`}>{area}</FluentOption>
                ))}
              </Dropdown>
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field label="Location" className={fieldStyle.editField}>
              <Input
                type="text"
                placeholder="New York, NY"
                value={experience.location ?? ""}
                onChange={(_evt, { value }) =>
                  updateExperience({
                    location: value,
                  })
                }
              />
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field
              label="Date"
              hint="Leave the second date blank if this engagement is ongoing"
              className={fieldStyle.editField}
            >
              <div className="flex flex-row gap-2 justify-between">
                <ReactDatePicker
                  className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
                  selected={
                    experience.startDate
                      ? dateStringToLocalDate(experience.startDate)
                      : null
                  }
                  onChange={(date) => {
                    const newDate = date ? localDateToDateString(date) : "";
                    updateExperience({
                      startDate: newDate,
                    });
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showIcon
                  icon={<CalendarSVG className="top-[2px]" />}
                />
                <p className="m-auto">&mdash;</p>
                <ReactDatePicker
                  className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
                  selected={
                    experience.endDate
                      ? dateStringToLocalDate(experience.endDate)
                      : null
                  }
                  onChange={(date) => {
                    const newDate = date ? localDateToDateString(date) : "";
                    updateExperience({
                      endDate: newDate,
                    });
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showIcon
                  icon={<CalendarSVG className="top-[2px]" />}
                />
              </div>
            </Field>
          </div>
          <div className="flex flex-col gap-2 self-stretch items-stretch">
            <Field
              label="Responsibilities and Outcomes"
              className={fieldStyle.editField}
            >
              <Textarea
                className={inputStyle.largeTextarea}
                value={experience.outcomes ?? ""}
                onChange={(_evt, { value }) =>
                  updateExperience({
                    outcomes: value,
                  })
                }
                resize="vertical"
              />
            </Field>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-1 items-start self-stretch">
            <div className="flex justify-between items-center self-stretch">
              <div className="flex gap-1 items-center">
                <p className="text-base font-semibold text-[##22234A]">
                  {experience.jobTitle} at {experience.companyName}
                </p>
                <Dropdown
                  className="w-full md:w-auto"
                  appearance="filled-darker"
                  value={experience.engagementType ?? ""}
                  selectedOptions={[experience.engagementType ?? ""]}
                  onOptionSelect={(_evt, data) =>
                    updateExperience({
                      engagementType: data.optionValue as EngagementType,
                    })
                  }
                  style={{
                    minWidth: "max-content",
                    borderRadius: "999px",
                  }}
                >
                  {engagementTypeList.map((option) => (
                    <FluentOption key={`experience-${option}`}>
                      {option}
                    </FluentOption>
                  ))}
                </Dropdown>
              </div>
              <div className="flex gap-3 content-end items-center">
                <Edit2SVG
                  className="cursor-pointer"
                  height="16"
                  width="16"
                  stroke="#727E94"
                  onClick={() => setEditMode(true)}
                />
                <TrashSVG
                  className="cursor-pointer"
                  height="16"
                  width="16"
                  stroke="#727E94"
                  onClick={deleteExperience}
                />
              </div>
            </div>
            <p className="self-stretch text-sm font-medium text-[#727E94]">{`${startString} - ${endString} (${experience.engagementType})`}</p>
            <div className="flex gap-1 items-center">
              <LocationSVG height="16" width="16" stroke="#4E4F6C" />
              <span className="text-sm font-medium text-[#4E4F6C]">
                {experience.location}
              </span>
            </div>
          </div>
          {experience.functions?.length ? (
            <div className="flex flex-col gap-2 items-start self-stretch">
              <p className="self-stretch text-sm font-semibold text-[#22234A]">
                {experience.functions?.length === 1 ? "Function" : "Functions"}
              </p>
              <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
                {experience.functions?.join(", ")}
              </p>
            </div>
          ) : null}
          <div className="flex flex-col gap-1 items-start self-stretch">
            <p className="self-stretch text-sm font-semibold text-[#22234A]">
              Responsibilities and Outcomes
            </p>
            <p className="self-stretch text-sm font-semibold text-[#4E4F6C] whitespace-break-spaces">
              {experience.outcomes}
            </p>
          </div>
        </>
      )}
      <div className="w-full h-[1px] bg-[#F2F2F2]" />
      <p className="self-stretch text-sm font-semibold text-[#22234A]">
        More about your role & company
      </p>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What does the company do?"
          className={fieldStyle.editField}
        >
          <Textarea
            placeholder="Describe it in one or two sentences"
            value={experience.companyDescription ?? ""}
            onChange={(_evt, data) =>
              updateExperience({ companyDescription: data.value })
            }
            resize="vertical"
          />
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What industry did you develop expertise in?"
          className={fieldStyle.editField}
        >
          <InfoLabel
            className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
            size="small"
            info={
              <p>
                If your experience was in multiple industries, choose the
                primary one.
              </p>
            }
          >
            Choose 1 industry from the list
          </InfoLabel>
          <Dropdown
            value={experience.industries ?? ""}
            selectedOptions={[experience.industries ?? ""]}
            onOptionSelect={(_evt, data) => {
              const option = data.optionValue as Industry;

              const update: Partial<IExperience> = { industries: option };
              if (option !== "Other") {
                update.otherIndustries = "";
              }

              updateExperience(update);
            }}
          >
            {industryList.map((option) => (
              <FluentOption key={`industry-${option}`}>{option}</FluentOption>
            ))}
          </Dropdown>
          {experience.industries === "Other" ? (
            <Input
              type="text"
              placeholder="Other industry"
              value={experience.otherIndustries}
              onChange={(_evt, { value }) =>
                updateExperience({ otherIndustries: value })
              }
            />
          ) : null}
        </Field>
      </div>
      <div className="flex flex-col gap-2 self-stretch items-stretch">
        <Field
          label="What stage was the company at when you hired?"
          className={fieldStyle.editField}
        >
          <Dropdown
            value={experience.stage ?? ""}
            selectedOptions={[experience.stage ?? ""]}
            onOptionSelect={(_evt, data) =>
              updateExperience({
                stage: data.optionValue as Stage,
              })
            }
          >
            {stageList.map((stage) => (
              <FluentOption key={`stage-${stage}`}>{stage}</FluentOption>
            ))}
          </Dropdown>
        </Field>
      </div>
      {editMode || changesMade ? (
        <Button
          type="primary"
          onClick={() => {
            nextFn();
            setEditMode(false);
          }}
        >
          Save
        </Button>
      ) : (
        <Button type="primary" onClick={nextFn}>
          Next
          <ArrowRightSVG height="16" width="16" stroke="white" />
        </Button>
      )}
    </div>
  );
};

const ProgressTracker = ({ currentStage }: { currentStage: number }) => {
  return (
    <div className="flex items-stretch gap-10">
      {STAGES.map((stage, idx) => {
        const isPastStage = idx < currentStage;
        const isCurrentStage = idx === currentStage;

        let className = "";
        let ellipseProps = {};

        if (isPastStage) {
          className = "complete";
          ellipseProps = {
            stroke: "#19A594",
            fill: "#19A594",
          };
        } else if (isCurrentStage) {
          className = "active";
          ellipseProps = {
            stroke: "#19A594",
          };
        }

        return (
          <div
            key={`progress-stage-${idx}`}
            className={`progress-bar-item ${className}`}
          >
            <p>{stage}</p>
            <EllipseSVG className="progress-bar-circle" {...ellipseProps} />
          </div>
        );
      })}
    </div>
  );
};

export default Onboarding;
