import { useNavigate } from "react-router-dom";
import { Button, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { EllipseSVG } from "../assets/svgs";
import { JobOpening, JobOpeningStatus } from "../redux/features/openingSlice";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";

const STAGES = [
  "Collecting Data",
  "Finding Candidates",
  "Interviews & Selection",
] as const;

const StatusToStageIndex: Record<JobOpeningStatus, 0 | 1 | 2> = {
  "New Opening": 0,
  "Creating Job Spec": 0,
  "Identifying Candidates": 1,
  "Presenting Shortlist": 2,
  "Role Filled (won)": 2,
  "Role Filled (lost)": 2,
};

export const NewOpportunityTile = ({
  opening,
  view = "dashboard",
}: {
  opening?: JobOpening;
  view?: "dashboard" | "opportunity";
}) => {
  const navigate = useNavigate();

  if (!opening) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
        <div className="flex gap-6 self-stretch">
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
        </div>
        <Skeleton className="w-full h-8">
          <SkeletonItem size={32} className="w-full" />
        </Skeleton>
      </div>
    );
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-base font-semibold text-[#22234A]">
            Title: {opening?.role}
          </p>
        </div>
        {view === "dashboard" ? (
          <Button
            appearance="secondary"
            shape="circular"
            onClick={() => navigate(`/opening/${opening.id}`)}
          >
            <p className="text-sm font-semibold text-[#4E4F6C]">See Details</p>
          </Button>
        ) : (
          <Button
            appearance="secondary"
            shape="circular"
            onClick={() => navigate(`/opening/${opening.id}/edit`)}
          >
            <p className="text-sm font-semibold text-[#4E4F6C]">Edit</p>
          </Button>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
        <OpportunityKeyPoint title="Scope" value={opening?.scope} />
        <OpportunityKeyPoint title="Retainer" value={opening?.budget} />
        <OpportunityKeyPoint
          title="Desired Start Date"
          value={
            opening?.desiredStartDate &&
            dateStringToLocalDate(
              opening?.desiredStartDate,
            )?.toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })
          }
        />
      </div>
      <ProgressTracker
        opportunityId={opening?.id ?? ""}
        currentStage={opening?.status ? StatusToStageIndex[opening?.status] : 0}
      />
    </div>
  );
};

const OpportunityKeyPoint = ({
  title,
  value,
}: {
  title: "Scope" | "Retainer" | "Desired Start Date";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1">
      <p className="text-sm font-medium text-[#727E94]">{title}:</p>
      <p className="text-sm font-semibold text-[#22234A]">{value}</p>
    </div>
  );
};

const ProgressTracker = ({
  opportunityId,
  currentStage,
}: {
  opportunityId: string;
  currentStage: number;
}) => {
  return (
    <div className="flex self-stretch items-stretch gap-10">
      {STAGES.map((stage, idx) => {
        const isPastStage = idx < currentStage;
        const isCurrentStage = idx === currentStage;

        let className = "";
        let ellipseProps = {};

        if (isPastStage) {
          className = "complete";
          ellipseProps = {
            stroke: "#19A594",
            fill: "#19A594",
          };
        } else if (isCurrentStage) {
          className = "active";
          ellipseProps = {
            stroke: "#19A594",
          };
        }

        return (
          <div
            key={`progress-stage-${opportunityId}-${idx}`}
            className={`progress-bar-item ${className} ${idx !== 0 && idx !== STAGES.length - 1 ? "flex-1" : ""}`}
          >
            <p>{stage}</p>
            <EllipseSVG className="progress-bar-circle" {...ellipseProps} />
          </div>
        );
      })}
    </div>
  );
};
