import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import networkErrorHandler from "../../utils/networkErrorHandler";
import { UserType } from "../../types";

export interface User {
  id: string;
  name?: string;
  emails: string[];
  profilePictureLink?: string;
  onboarded?: boolean;
  userType: UserType[];
  companyIds: string[];
}

// Define a type for the slice state
interface AuthState {
  user?: User;
  isAuthenticated: boolean;
  status: "idle" | "pending" | "succeeded" | "failed";
  postingStatus: "idle" | "posting" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: AuthState = {
  isAuthenticated: false,
  status: "idle",
  postingStatus: "idle",
};

export const login = createAsyncThunk(
  "auth/login",
  async (token: string, { rejectWithValue }) => {
    try {
      const response: { data: User } = await axios.get("/api/user/getUser", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const completeOnboarding = createAsyncThunk(
  "auth/onboard",
  async (token: string, { rejectWithValue }) => {
    try {
      const response: { data: User } = await axios.post(
        "/api/user/onboard",
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logout: (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<User | undefined>) => {
          state.status = "succeeded";
          state.user = action?.payload;
          state.isAuthenticated = true;
        },
      )
      .addCase(login.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(completeOnboarding.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        completeOnboarding.fulfilled,
        (state, action: PayloadAction<User | undefined>) => {
          state.status = "succeeded";
          state.user = action?.payload;
        },
      )
      .addCase(completeOnboarding.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
