import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import networkErrorHandler from "../../utils/networkErrorHandler";

export interface JobOpening {
  id?: string;
  companyRecordId?: string;
  role?: string;
  jobDescriptionLink?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  fullTimeConversionPossible?: boolean;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  status?: JobOpeningStatus;
  candidates?: CandidateStatus[];
  interviews?: Interview[];
}

export const jobOpeningStatusList = [
  "New Opening",
  "Creating Job Spec",
  "Identifying Candidates",
  "Presenting Shortlist",
  "Role Filled (won)",
  "Role Filled (lost)",
] as const;
export type JobOpeningStatus = (typeof jobOpeningStatusList)[number];

// Define a type for the slice state
interface OpeningState {
  opening?: JobOpening;
  openings: JobOpening[];
  status: "idle" | "pending" | "succeeded" | "failed";
  postingStatus: "idle" | "posting" | "succeeded" | "failed";
}

export interface CandidateStatus {
  id: string;
  candidateId: string;
  name?: string;
  headline?: string;
  profilePictureLink?: string;
  status?: CandidateApplicationStatus;
  scope?: string;
  agreesWithScope?: boolean;
  budget?: string;
  agreesWithPay?: boolean;
  earliestStartDate?: string;
  agreesWithStartDate?: boolean;
  openToFullTime?: boolean;
}

export const candidateApplicationStatusList = [
  "Identified",
  "Checking Interest",
  "Screening",
  "Possible Fit",
  "Shortlisted",
  "Accepted by Client",
  "Rejected for Role",
  "Not Interested",
] as const;
export type CandidateApplicationStatus =
  (typeof candidateApplicationStatusList)[number];

export interface Interview {
  id?: string;
  name?: string;
  duration?: number;
  participants?: string[];
  step?: number;
  interviewRequested?: string[];
  interviewCompleted?: string[];
}

// Define the initial state using that type
const initialState: OpeningState = {
  openings: [],
  status: "idle",
  postingStatus: "idle",
};

export const getOpenings = createAsyncThunk(
  "openings/get",
  async (
    { token, signal }: { token: string; signal: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: JobOpening[] } = await axios.get(
        `/api/jobOpening/`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
          signal,
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const getOpening = createAsyncThunk(
  "opening/get",
  async (
    {
      id,
      token,
      signal,
      getCandidates,
      getInterviews,
    }: {
      id: string;
      token: string;
      signal: AbortSignal;
      getCandidates?: boolean;
      getInterviews?: boolean;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: JobOpening } = await axios.get(
        `/api/jobOpening/${id}?candidates=${Boolean(getCandidates)}&interviews=${Boolean(getInterviews)}`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
          signal,
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const createOpening = createAsyncThunk(
  "opening/post",
  async (
    { token, data }: { token: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: JobOpening } = await axios.post(
        `/api/jobOpening/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateOpening = createAsyncThunk(
  "opening/post",
  async (
    { id, token, data }: { id: string; token: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: JobOpening } = await axios.patch(
        `/api/jobOpening/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const scheduleInterview = createAsyncThunk(
  "interview/post",
  async (
    {
      openingId,
      candidateId,
      interviewId,
      token,
    }: {
      openingId: string;
      candidateId: string;
      interviewId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Interview } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateId}/interview/${interviewId}/schedule`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const completeInterview = createAsyncThunk(
  "interview/post",
  async (
    {
      openingId,
      candidateId,
      interviewId,
      token,
    }: {
      openingId: string;
      candidateId: string;
      interviewId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Interview } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateId}/interview/${interviewId}/complete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const rejectCandidate = createAsyncThunk(
  "candidate/post",
  async (
    {
      openingId,
      candidateId,
      token,
    }: {
      openingId: string;
      candidateId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: CandidateStatus[] } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateId}/reject`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const acceptCandidate = createAsyncThunk(
  "candidate/post",
  async (
    {
      openingId,
      candidateId,
      token,
    }: {
      openingId: string;
      candidateId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: CandidateStatus[] } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateId}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const openingSlice = createSlice({
  name: "opening",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetPostingStatus: (state) => {
      state.postingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOpenings.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getOpenings.fulfilled,
        (state, action: PayloadAction<JobOpening[] | undefined>) => {
          state.openings = action.payload ?? [];
          state.status = "succeeded";
        },
      )
      .addCase(getOpening.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getOpening.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getOpening.fulfilled,
        (state, action: PayloadAction<JobOpening | undefined>) => {
          state.opening = action.payload;
          state.status = "succeeded";
        },
      )
      .addCase(getOpenings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createOpening.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        createOpening.fulfilled,
        (state, action: PayloadAction<JobOpening | undefined>) => {
          state.opening = action.payload;
          state.postingStatus = "succeeded";
        },
      )
      .addCase(createOpening.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(scheduleInterview.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        scheduleInterview.fulfilled,
        (state, action: PayloadAction<Interview | undefined>) => {
          if (state.opening?.interviews && action.payload?.id) {
            const interviews = [...state.opening.interviews];
            const idx = interviews.findIndex(
              ({ id }) => id === action.payload?.id,
            );

            if (idx !== -1) {
              interviews[idx] = action.payload;
              state.opening.interviews = interviews;
            }
          }
          state.postingStatus = "succeeded";
        },
      )
      .addCase(scheduleInterview.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(rejectCandidate.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        rejectCandidate.fulfilled,
        (state, action: PayloadAction<CandidateStatus[] | undefined>) => {
          if (state.opening) {
            state.opening.candidates = action.payload ?? [];
          }
          state.postingStatus = "succeeded";
        },
      )
      .addCase(rejectCandidate.rejected, (state) => {
        state.postingStatus = "failed";
      });
  },
});

export const { resetPostingStatus } = openingSlice.actions;

export default openingSlice.reducer;
