import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./features/errorSlice";
import authReducer from "./features/authSlice";
import candidateProfileReducer from "./features/candidateProfileSlice";
import companyProfileReducer from "./features/companyProfileSlice";
import openingReducer from "./features/openingSlice";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    auth: authReducer,
    candidateProfile: candidateProfileReducer,
    companyProfile: companyProfileReducer,
    opening: openingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
