import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  ArrowExitRegular,
  EditRegular,
  PeopleSearchRegular,
  PeopleTeamRegular,
} from "@fluentui/react-icons";
import { VenturousSVG } from "../assets/svgs";
import { useMenuStyles } from "../fluentStyles";

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <nav
      className={`${pathname === "/onboarding" ? "hidden" : "flex"} sticky top-0 z-10 justify-between items-center border-bottom-line bg-white`}
    >
      <div className="h-[72px] py-4 px-[40px] flex gap-1 items-center">
        <VenturousSVG />
        <p className="font-logo text-[37.894px] tracking-[-1.894px] text-[#19A594] cursor-default">
          ENTUROUS
        </p>
      </div>
      <Notifications />
    </nav>
  );
};

const Notifications = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const user = useAppSelector((state) => state.auth.user);
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();

  const menuStyles = useMenuStyles();

  const userImage = user?.profilePictureLink;
  const userName = user?.name;
  const userType = user?.userType;

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  const handleLogout = () => {
    void logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isCandidate =
    userType?.includes("Admin") || userType?.includes("Candidate");
  const isCompany =
    userType?.includes("Admin") || userType?.includes("Company");
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  if (!isAuthenticated && !isLoading) {
    return (
      <div className="pr-10 flex-1 flex flex-row gap-3 items-center justify-end">
        <Button appearance="primary" size="medium" onClick={handleLogin}>
          Log In
        </Button>
        <Button appearance="secondary" size="medium" onClick={handleSignUp}>
          Sign Up
        </Button>
      </div>
    );
  }

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Avatar
            className={menuStyles.userButton}
            image={{ src: userImage }}
            name={userName ?? "User"}
            color="brand"
            size={32}
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {isCandidate ? (
              <MenuItem
                icon={<EditRegular />}
                onClick={() => navigate("/profile/edit")}
              >
                Profile Information
              </MenuItem>
            ) : null}
            {isCompany ? (
              <>
                <MenuItem
                  icon={<PeopleSearchRegular />}
                  onClick={() => navigate("/opening")}
                >
                  Manage Opportunities
                </MenuItem>
                <MenuItem
                  icon={<PeopleTeamRegular />}
                  onClick={() => navigate("/team")}
                >
                  Manage Team
                </MenuItem>
              </>
            ) : null}
            <MenuItem icon={<ArrowExitRegular />} onClick={handleLogout}>
              Log Out
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );
};

export default Navigation;
